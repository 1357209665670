import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import LinkBoxes from "components/footer/LinkBoxes"
import FlexibleContent from "components/flexibleContent/FlexibleContent"
import SidebarContent from "components/sidebarContent"

import { getUrlPath } from "utils/getUrlPath"
import * as theme from "theme"
import WideImage from "components/wideImage"

const Home = (props) => {
  const {
    data: {
      wp: {
        themeSettings: {
          siteOptions: { notifications, sectionHeroImage, sidebarOverride }
        }
      },
      wpPage: {
        title,
        uri,
        acfModules: {
          edgesSize,
          headerImage,
          headerVideo,
          hideHeader,
          hideMemberAds,
          hideNewsletterTeaser,
          showNotifications,
          hideSidebar,
          hideSearch,
          hideFooterLinks,
          footerImage,
          modules
        }
      }
    },
    pageContext,
    uri: pathname
  } = props

  const notification = notifications.find((o) => o.languageCode === process.env.GATSBY_ACTIVE_LANGUAGE)

  const getSectionImage = () => {
    if (!pageContext || !pageContext.translations) {
      return null
    }

    const defaultTranslation =
      process.env.GATSBY_ACTIVE_LANGUAGE === "EN"
        ? { uri: pathname.replace("/", "") }
        : pageContext.translations.find((o) => o.language.slug === "en")

    if (defaultTranslation) {
      const defaultPath =
        process.env.GATSBY_ACTIVE_LANGUAGE === "EN"
          ? defaultTranslation.uri.replace("en/", "").split("/")
          : defaultTranslation.uri.replace("/en/", "").split("/")

      if (defaultPath && defaultPath.length > 0) {
        const section = sectionHeroImage.find((o) => defaultPath[0].includes(o.path))

        if (section) {
          return section.image
        }
      }
    }

    return null
  }

  return (
    <>
      <SidebarContent
        image={headerImage || getSectionImage()}
        youtubeVideoId={headerVideo}
        title={title}
        slug={getUrlPath(uri)}
        hideSidebar={sidebarOverride ? true : hideSidebar}
        hideSidebarOnMobile
        hideHeader={hideHeader}
        edgesSize={edgesSize}
      >
        {!!modules && (
          <FlexibleContent
            rows={modules}
            data={{
              title,
              uri,
              hideMemberAds,
              hideNewsletterTeaser,
              hideSidebar,
              hideSearch
            }}
          />
        )}

        {notification && showNotifications && (
          <>
            {showNotifications.includes("metrics") && <Notification children={notification.noteForMetrics} />}
            {showNotifications.includes("standards") && (
              <Notification children={notification.noteForNorthAmericanStandards} />
            )}
          </>
        )}
      </SidebarContent>

      {footerImage && (
        <WideImageContainer>
          <WideImage image={footerImage} />
        </WideImageContainer>
      )}

      {hideFooterLinks !== true && <LinkBoxes marginTop={footerImage ? 0 : "5vh"} />}
    </>
  )
}

const Notification = styled(Typography)`
  width: 100%;
  margin: 30px 0;
  padding: 20px;
  border: 1px solid ${theme.colors.secondary};
`
const WideImageContainer = styled.div`
  margin-top: 5vh;
`

export const CollectionQuery = graphql`
  query DefaultTemplate($id: String!) {
    wp {
      themeSettings {
        siteOptions {
          sidebarOverride
          notifications {
            languageCode
            noteForMetrics
            noteForNorthAmericanStandards
          }
          sectionHeroImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, layout: FULL_WIDTH)
                }
              }
            }

            path
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        edgesSize
        hideHeader
        headerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH)
            }
          }
        }
        hideMemberAds
        hideNewsletterTeaser
        showNotifications: notifications
        hideSidebar
        hideSearch
        hideFooterLinks
        headerVideo
        footerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(height: 573, layout: FULL_WIDTH, quality: 80)
            }
          }
        }
        modules {
          ... on WpPage_Acfmodules_Modules_Textarea {
            text
            __typename
          }
          ... on WpPage_Acfmodules_Modules_HeroImage {
            headline
            __typename
          }
          ... on WpPage_Acfmodules_Modules_LogoTitle {
            headline
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 50, layout: FIXED)
                }
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Publication {
            publication {
              publicationTitle
              publicationDescription
              orderLink
              publicationImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 150, layout: FIXED)
                  }
                }
              }
              downloadLinks {
                language
                links {
                  sourceUrl
                  localFile {
                    publicURL
                  }
                }
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Shop {
            __typename
            introduction
            formId
            cartFieldId
            sections {
              headline
              max
              maxPerItem
              items {
                name
                image {
                  sourceUrl
                }
                link {
                  title
                  url
                  target
                }
              }
            }
          }
          ... on WpPage_Acfmodules_Modules_Boxes {
            headline
            buttonVisibility
            boxes {
              headline
              text
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_FaqAccordion {
            headline
            boxes {
              title
              text
              link {
                target
                title
                url
              }
              link2 {
                target
                title
                url
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_TextareaCta {
            cta {
              button {
                title
              }
              text
            }
            text
            __typename
          }
          ... on WpPage_Acfmodules_Modules_TextareaVideo {
            text
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
                }
              }
            }
            link {
              url
              title
              target
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Downloads {
            headline
            downloads {
              category
              title
              link {
                target
                title
                url
              }
              download {
                localFile {
                  publicURL
                }
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Videos {
            headline
            videos {
              fieldGroupName
              title
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Tabs {
            tabs {
              title
              text
            }
            tabsImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
                }
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Calculator {
            calculator
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Form {
            formId
            hideTitle
            hiddenFields {
              gfFieldId
              value
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Accordion {
            headline
            readMoreLabel
            items {
              title
              subtitle
              text
              hiddenText
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Members {
            headline
            category {
              ... on WpMemberType {
                name
                databaseId
              }
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Architects {
            headline
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Gallery {
            headline
            link {
              url
              title
              target
            }
            categories
            __typename
          }
          ... on WpPage_Acfmodules_Modules_NewsletterSignup {
            headline
            __typename
          }
          ... on WpPage_Acfmodules_Modules_NewsletterModule {
            headline
            subheadline
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Callout {
            text
            link {
              url
              title
              target
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_Cards {
            items {
              button {
                target
                title
                url
              }
              buttonIcon
              headline
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
                  }
                }
              }
              text
              textWysiwyg
            }
            __typename
          }
          ... on WpPage_Acfmodules_Modules_TextImage {
            content
            alignment
            link {
              target
              title
              url
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 550, quality: 80, layout: CONSTRAINED)
                }
              }
            }
            __typename
          }
        }
      }
    }
  }
`

export default Home
